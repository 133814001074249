import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SideBar.css";
import { Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const SideBarComponent = ({
  addNewButtonClicked,
  editUser,
  onClose,
  formData = {},
  handleSave,
  handleInputChange,
  masterData,
}) => {
  const status_data = [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
  ];
  const customStyles = () => ({
    control: (provided, state) => ({
      ...provided,
      marginBottom: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 9999,
      color: "black",
    }),
  });
  return (
    <div className={"sidenav"}>
      <div
        className="gtm-sidebar-header"
        style={{
          borderBottom: "1px solid lightgrey",
          alignItems: "center",
        }}
      >
        <h6
          style={{
            flex: "1",
            minWidth: "70%",
            maxWidth: "80%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textAlign: "left",
            paddingTop: "4px",
          }}
        >
          {"User Management > Add user"}
        </h6>
        <a href="#" className="closebtn" onClick={onClose}>
          &times;
        </a>
      </div>
      <div className="gtm-sidebar-body">
        <div className="row" style={{ marginTop: "25px" }}>
          <div className="inputField">
            <div className="side_bar_heading">
              <div>First Name </div>
              <span style={{ color: "red" }}>*</span>
            </div>

            <input
              className="form-control"
              style={{ marginLeft: "10px" }}
              required
              type="text"
              value={formData.firstName}
              placeholder="Enter your First name"
              onChange={(e) => handleInputChange("firstName", e.target.value)}
            />
          </div>
          <div className="inputField">
            <div className="side_bar_heading">
              <div>Last Name</div>
            </div>
            <input
              className="form-control"
              style={{ marginLeft: "16px" }}
              type="text"
              value={formData.lastName}
              placeholder="Enter your Last name"
              onChange={(e) => handleInputChange("lastName", e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className=" inputField">
            <div className="side_bar_heading">
              <div htmlFor="genderSelect">Gender </div>
              <span style={{ color: "red" }}>*</span>
            </div>
            <Select
              required
              className="status-select gender"
              styles={customStyles}
              placeholder="Select gender"
              options={masterData.gender}
              value={
                masterData &&
                formData &&
                masterData.gender.find(
                  (option) => option.value === formData.selectedGender
                )
              }
              onChange={(e) => handleInputChange("selectedGender", e.value)}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img alt="" src={e.icon} /> */}
                  <span style={{ marginLeft: 5 }}>{e.label}</span>
                </div>
              )}
            />
          </div>
          <div className=" inputField">
            <div className="side_bar_heading">
              <div>Email </div>
              <span style={{ color: "red" }}>*</span>
            </div>
            <input
              className="form-control"
              required
              style={{ marginLeft: "43px" }}
              type="email"
              value={formData.email}
              placeholder="Enter your Email"
              onChange={(e) => handleInputChange("email", e.target.value)}
              disabled={
                formData.email !== "" && !addNewButtonClicked ? true : false
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="inputField" style={{ marginLeft: "3px" }}>
            <div className="side_bar_heading">
              <div>Selected BI </div>
            </div>
            <Select
              className="status-select bi"
              styles={customStyles}
              placeholder="Select BI"
              options={masterData.bi_list}
              value={
                masterData &&
                formData &&
                masterData.bi_list.filter((option) =>
                  formData.selectBI.includes(option.value)
                )
              }
              onChange={(e) =>
                handleInputChange(
                  "selectBI",
                  e.map((option) => option.value)
                )
              }
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginLeft: 5 }}>{e.label}</span>
                </div>
              )}
              isMulti
            />
          </div>
          {editUser && (
            <div className=" inputField">
              <div className="side_bar_heading">
                <div style={{ marginRight: "5px" }}>Status </div>
                {/* <span style={{ color: "red" }}>*</span> */}
              </div>
              <Select
                className="status-select status"
                styles={customStyles}
                placeholder="Select status"
                options={status_data}
                value={
                  masterData &&
                  formData &&
                  status_data.find((option) => option.value === formData.status)
                }
                onChange={(e) => handleInputChange("status", e.value)}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <img alt="" src={e.icon} /> */}
                    <span style={{ marginLeft: 5 }}>{e.label}</span>
                  </div>
                )}
              />
            </div>
          )}
        </div>
        <div
          style={{
            textAlign: "right",
            paddingLeft: "30px",
            paddingTop: "15vh",
            paddingBottom: "10px",
            marginRight: "20px",
          }}
        >
          <Button
            type="button"
            style={{
              cursor: "pointer",
              backgroundColor: "#0A5F59",
              text: "center",
              padding: "4px 10px",
              border: "1px solid var(--rs-border-primary)",
              position: "absolute",
              top: "10px",
              right: "70px",
            }}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SideBarComponent;
