import React, { useState, useEffect } from "react";
import { userLogin, userUpdatePassword } from "../store/actions/index.js";
import { useSelector, useDispatch } from "react-redux";
import Image from "../assets/images/XL.png";
import { Form, Button } from "react-bootstrap";
import "../components/LoginComponent.css";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../components/Loader.js";
import Cookies from "js-cookie"; // Import the js-cookie library
import Alert from "./Alert.js";

function UpdatePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [key, setKey] = useState(""); // State to store the key parameter
  const [errors, setErrors] = useState({}); // State to store validation errors
  const [errorMessage, setErrorMessage] = useState(""); // State to store error messages
  const [isPasswordValid, setIsPasswordValid] = useState(false); // State to check if password is valid
  const fetchuserUpdatePasswordDetails = useSelector(
    (state) => state.fetchuserUpdatePasswordDetails
  );
  const isUpdatePasswordFetched = useSelector(
    (state) => state.isUpdatePasswordFetched
  );
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const keyParam = searchParams.get("key");
    if (keyParam) {
      setKey(keyParam);
    } else {
      setErrorMessage("Invalid URL: Missing key parameter");
    }
  }, [location]);

  const validatePassword = (password) => {
    const errors = {};
    const passwordRegEx = {
      minLength: /.{8,}/,
      upperCase: /[A-Z]/,
      lowerCase: /[a-z]/,
      digit: /[0-9]/,
      specialChar: /[!@#$%^&*(),.?":{}|<>]/,
    };

    if (
      !passwordRegEx.minLength.test(password) ||
      !passwordRegEx.upperCase.test(password) ||
      !passwordRegEx.lowerCase.test(password) ||
      !passwordRegEx.digit.test(password) ||
      !passwordRegEx.specialChar.test(password)
    ) {
      errors.hint = (
        <div className="hint-message">
          Better add a few more letters or another word. <br />
          Hint: Include symbols, numbers and capital letters in the password.
        </div>
      );
    }
    return errors;
  };

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    setNewPassword(password);
    const validationErrors = validatePassword(password);
    setErrors(validationErrors);
    setIsPasswordValid(Object.keys(validationErrors).length === 0);
  };

  const handleConfirmPasswordChange = (event) => {
    const password = event.target.value;
    setConfirmPassword(password);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }
    setErrorMessage(null);
    if (isPasswordValid) {
      setShowLoader(true);
      dispatch(userUpdatePassword(newPassword, key));
    }
  };
  useEffect(() => {
    if (isUpdatePasswordFetched) {
      if (
        fetchuserUpdatePasswordDetails &&
        fetchuserUpdatePasswordDetails.status === 200
      ) {
        setShowLoader(false);
        Alert.success({
          title: "Password Set Successfully",
          text: "Redirecting...",
          icon: "info",
          showConfirmButton: false,
          timer: 5000,
        });
        // Using setTimeout to redirect after 5 seconds
        setTimeout(() => {
          navigate("/");
        }, 5000);
      } else if (
        fetchuserUpdatePasswordDetails &&
        fetchuserUpdatePasswordDetails.status === 410
      ) {
        setShowLoader(false);
        Alert.warning({
          title:
            "The reset password link has either been used before or is invalid",
          text: "Please contact the Admin team",
          icon: "warning",
          showConfirmButton: true,
        });
      }
    }
  }, [isUpdatePasswordFetched]);

  return (
    <>
      {showLoader && <Loader />}
      <div
        className="pagecenter loginForm"
        style={{ padding: "10%", width: "100%" }}
      >
        <Form
          className="modal-content animate"
          onSubmit={handleSubmit}
          style={{ width: "500px", maxWidth: "500px" }}
        >
          <div
            className="container1"
            style={{ width: "90%", paddingBottom: "5%", paddingLeft: "10%" }}
          >
            <h3 style={{ textAlignVertical: "center", textAlign: "center" }}>
              <img style={{ width: "7%" }} src={Image} />
              Excelrate Admin
            </h3>
            <Form.Group size="lg" controlId="text">
              <Form.Control
                autoFocus
                name="newpassword"
                type="password"
                value={newPassword}
                onChange={handlePasswordChange}
                required
                placeholder="New Password"
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group size="lg" controlId="password">
              <Form.Control
                name="confirmpassword"
                type="password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
                placeholder="Confirm Password"
              />
            </Form.Group>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            {errors.hint && <div className="error-message">{errors.hint}</div>}
            {isPasswordValid && (
              <div className="success-message">
                Success! You are good to go 👍
              </div>
            )}
            <Button size="lg" type="submit" style={{ height: "50px" }}>
              Confirm
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default UpdatePassword;
