import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Image from "../assets/images/XL.png";
import { Form, Button } from "react-bootstrap";
import "../components/LoginComponent.css";
import Loader from "../components/Loader.js";
import Alert from "./Alert.js";
import { userForgotPassword } from "../store/actions/index.js";
import { AdminPortal } from "../store/apiConfig.js";
export const domain = await AdminPortal();

function ForgotPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [timer, setTimer] = useState(0);

  const fetchuserForgotPasswordDetails = useSelector(
    (state) => state.fetchuserForgotPasswordDetails
  );
  const isForgotPasswordFetched = useSelector(
    (state) => state.isForgotPasswordFetched
  );

  useEffect(() => {
    const savedTimer = localStorage.getItem("forgotPasswordTimer");
    if (savedTimer) {
      const timeRemaining = parseInt(savedTimer, 10);
      const now = Date.now();
      const timeElapsed = Math.floor(
        (now - localStorage.getItem("forgotPasswordTimestamp")) / 1000
      );
      const newTimer = Math.max(timeRemaining - timeElapsed, 0);
      setTimer(newTimer);
    }
  }, []);

  useEffect(() => {
    if (timer > 0) {
      localStorage.setItem("forgotPasswordTimer", timer);
      localStorage.setItem("forgotPasswordTimestamp", Date.now());
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer - 1;
          if (newTimer <= 0) {
            clearInterval(countdown);
            localStorage.removeItem("forgotPasswordTimer");
            localStorage.removeItem("forgotPasswordTimestamp");
          }
          return newTimer;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [timer]);

  const validateEmail = (email) => {
    const errors = {};
    const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegEx.test(email)) {
      errors.hint = (
        <div className="hint-message">Please enter a valid email address.</div>
      );
    }
    return errors;
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmail(email);
    const validationErrors = validateEmail(email);
    setErrors(validationErrors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoader(true);
    if (timer > 0) {
      return; // Prevent form submission if timer is active
    }
    const validationErrors = validateEmail(email);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    dispatch(userForgotPassword(email, domain));
  };

  useEffect(() => {
    if (isForgotPasswordFetched) {
      if (
        fetchuserForgotPasswordDetails &&
        fetchuserForgotPasswordDetails?.data?.status === 200
      ) {
        setTimer(120);
        setShowLoader(false);
        Alert.success({
          title: "Email Sent Successfully",
          text: "Please check your email for further instructions",
          timer: 5000,
          icon: "info",
          showConfirmButton: false,
        });
      } else if (
        (fetchuserForgotPasswordDetails &&
          fetchuserForgotPasswordDetails?.data?.status === 404) ||
        fetchuserForgotPasswordDetails?.status === 404
      ) {
        setShowLoader(false);
        Alert.warning({
          title: "It is not a valid Email",
          text: "Please contact the Admin team",
          icon: "warning",
          showConfirmButton: true,
          preConfirm: (isConfirm) => {
            if (isConfirm) {
              window.location.reload();
            } else {
            }
          },
        });
      }
    }
  }, [isForgotPasswordFetched]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <>
      {showLoader && <Loader />}
      <div
        className="pagecenter loginForm"
        style={{ padding: "10%", width: "100%" }}
      >
        <Form
          className="modal-content animate"
          onSubmit={handleSubmit}
          style={{ width: "500px", maxWidth: "500px" }}
        >
          <div
            className="container1"
            style={{ width: "90%", paddingBottom: "5%", paddingLeft: "10%" }}
          >
            <h3 style={{ textAlignVertical: "center", textAlign: "center" }}>
              <img style={{ width: "7%" }} src={Image} alt="Excelrate Logo" />
              Excelrate Admin
            </h3>
            <Form.Group size="lg" controlId="email">
              <Form.Control
                autoFocus
                name="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
                placeholder="Enter your email"
                autoComplete="off"
              />
            </Form.Group>
            {errors.hint && <div className="error-message">{errors.hint}</div>}
            {timer > 0 ? (
              <div className="timer-message">
                Resend Email in {formatTime(timer)}
              </div>
            ) : (
              <Button size="lg" type="submit" style={{ height: "50px" }}>
                Send
              </Button>
            )}
          </div>
        </Form>
      </div>
    </>
  );
}

export default ForgotPassword;
