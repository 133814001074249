import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Loader from "./Loader";

const UsersComponent = ({
  rows,
  handleRowSelectionModelChange,
  handleAddClick,
  handleInActiveClick,
  handleCellClick,
}) => {
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 200,
      renderCell: (params) => (
        <div
          onClick={(e) => handleCellClick(e, params)}
          style={{
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "firstName",
      headerName: "First name",
      width: 250,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      width: 250,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
  ];
  const getRowId = (row) => row.id;
  if (!localStorage.getItem("authenticated")) {
    return (
      <div>
        <a href="/logout">Login Again</a>
      </div>
    );
  } else {
    return (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: "10px",
              fontSize: "var(--main-heading)",
              fontWeight: "var(--heading-font-weight)",
            }}
          >
            User Management
          </div>
          <div
            className="btn-group"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            <button className="btn btn-success" onClick={handleAddClick}>
              + Add
            </button>
            <button className="btn btn-danger" onClick={handleInActiveClick}>
              In-Active
            </button>
          </div>
        </div>
        <br />
        <div style={{ width: "100%", height: "80vh" }}>
          <DataGrid
            style={{
              fontFamily: "Source Sans Pro",
              fontSize: "var(--sub-heading-font-size)",
            }}
            rows={rows}
            getRowId={getRowId}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            checkboxSelection
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            onRowSelectionModelChange={handleRowSelectionModelChange}
          />
        </div>
      </>
    );
  }
};

export default UsersComponent;
